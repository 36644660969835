import axios, {AxiosResponse} from "axios";
import {IDay, IDayForCreation} from "../models/day";

axios.defaults.baseURL = 'https://aulaga-api.restaurantepedagogicoaulaga.es/api/';
// axios.defaults.baseURL = 'http://localhost:5000/api';

const responseBody = (response: AxiosResponse) => response.data;

const responsePaginateBody = (response: AxiosResponse) => {
    return {
        days: response.data,
        totalCountRecords: parseInt(response.headers['totalcountrecords'], 10)
    }
};

const request = {
    listed: (url: string, token: string, page: number, recordsPerPage: number) => axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            page,
            recordsPerPage
        }
    }).then(responsePaginateBody),
    get: (url: string, token: string) => axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    post: (url: string, body: {}, token: string) => axios.post(url, body, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    put: (url: string, body: {}, token: string) => axios.put(url, body, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    delete: (url: string, token: string) => axios.delete(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody)
};

const Days = {
    list: (token: string, page: number, recordPerPage: number) : Promise<{days: any, totalCountRecords: number}> => request.listed('/day', token, page, recordPerPage),
    listHistory: (token: string, page: number, recordPerPage: number) : Promise<{days: any, totalCountRecords: number}> => request.listed('/day/history', token, page, recordPerPage),
    get: (id: number, token: string) : Promise<IDayForCreation> => request.get(`/day/${id}`, token),
    create: (day: IDayForCreation, token: string, name: string) => request.post(`/day/${name}`, day, token),
    put: (id: number, day: IDayForCreation, token: string, name: string) => request.put(`/day/${id}/${name}`, day, token),
    delete: (id: number, token: string, name: string) => request.delete(`/day/${id}/${name}`, token)
};

export default Days;
