import React, {useContext, useEffect, useState} from 'react';
import {IReservationForCreation} from "../../models/reservation";
import LoadingComponent from "../ui/LoadingComponent";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useHistory, useParams } from 'react-router-dom';
import reservation from "../../api/reservation";
import {reservationValidation} from "../../validation/reservationValidation";
import {AuthContext} from "../../context/AuthContext";
import NormalTextareaComponent from "../ui/NormalTextareaComponent";
import {IOtherDetail} from "../../models/other";
import other from "../../api/other";
import InformationModalComponent from "../ui/InformationModalComponent";
import {Transition} from "@headlessui/react";

interface IReservationFormComponent {
    showNotification?: any,
    messageNotification?: any,
    requestType: string,
    showSidebar: boolean,
    setShowSidebar: any
}

interface IRouterParams {
    dayId: string,
    id: string
}

const ReservationFormComponent = (props: IReservationFormComponent) => {

    const labelStyles = "font-bold mb-2";
    const inputStyles = "border-1 border-primary rounded-lg p-1 mb-2";

    const [loading, setLoading] = useState(false);
    const [allergens, setAllergens] = useState('');

    const params = useParams<IRouterParams>();
    const history = useHistory();
    const { user, userDispatch } = useContext(AuthContext);

    const initialState: IReservationForCreation = {
        name: "",
        surname: "",
        telefone: "",
        email: "",
        numberOfPeople: 0,
        allergens: '',
        dayId: parseInt(params.dayId),
        isTermsAndPrivacyAccepted: false,
        isTheyWantReceiveOffers: false
    };

    const [others, setOthers] = useState<IOtherDetail>({
        teamDescription: '',
        phoneNumber: '',
        urlForm: '',
        termsAndConditions: '',
        privacyAndPolicies: ''
    });

    const [reservationState, setReservationState] = useState<IReservationForCreation>(initialState);

    const [showModal, setShowModal] = useState(false);
    const [bodyModal, setBodyModal] = useState({
        title: '',
        message: '',
        buttonText: ''
    });

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La cantidad de personas supera el aforo');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    useEffect(() => {
        setLoading(true);
        other.get().then((response => setOthers(response)))
            .catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false));
        if (props.requestType === "update") {
            setLoading(true);
            reservation.get(params.id, user?.user?.token as string)
                .then((response) => setReservationState(response))
                .catch((error) => checkError(error.toString()))
                .finally(() => setLoading(false))
        }

    }, []);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in ' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={(loading) ? "hidden" : "block w-full h-full md:py-4 md:px-20"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                </div>

                <div className={"mt-4 flex flex-col p-8 w-full lg:w-1/2 shadow-custom rounded-lg animate-fade-in"}>
                    <div className={"flex flex-row w-full items-center"}>
                        <button className={"rounded-full hover:bg-gray-50 mr-2"} onClick={() => history.goBack()}>
                            <div className={"w-8 h-8 p-2"}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back" d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z" transform="translate(-6 -6)"/>
                                </svg>
                            </div>
                        </button>
                        {(props.requestType !== "update") ?
                            <p className={"font-bold text-primary text-lg"}>Asignar reserva</p> :
                            <p className={"font-bold text-primary text-lg"}>Actualizar reserva</p>}
                    </div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={reservationState}
                        onSubmit={async values => {
                            setLoading(true);
                            if (props.requestType === "create") {
                                reservation.create(values)
                                    .then(() => {
                                        setLoading(false);
                                        history.goBack();
                                        props.messageNotification('Se ha insertado correctamente');
                                        props.showNotification(true);
                                    })
                                    .catch((error) => checkError(error.toString()))
                                    .finally(() => setLoading(false));
                            } else if (props.requestType === "update") {
                                reservation.put(params.id,
                                    parseInt(params.dayId), values, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                                    + user?.user?.userResponse?.lastName as string)
                                    .then(() => {
                                        setLoading(false);
                                        history.goBack();
                                        props.messageNotification('Se ha actualizado correctamente');
                                        props.showNotification(true);
                                    })
                                    .catch((error) => checkError(error))
                                    .finally(() => setLoading(false));
                            }
                        }}
                        validationSchema={reservationValidation}>
                        {(formikProps) => (
                            <Form className={"mt-4"}>
                                <div className={"flex flex-col"}>
                                    <label htmlFor={"name"} className={labelStyles}>Nombre*</label>
                                    <Field type={"text"} name={"name"} className={inputStyles} />
                                    <ErrorMessage name={"name"}>{message =>
                                        <p className={"text-red-500"}>{message}</p>
                                    }</ErrorMessage>
                                </div>
                                <div className={"flex flex-col"}>
                                    <label htmlFor={"surname"} className={labelStyles}>Apellidos*</label>
                                    <Field type={"text"} name={"surname"} className={inputStyles} />
                                    <ErrorMessage name={"surname"}>{message =>
                                        <p className={"text-red-500"}>{message}</p>
                                    }</ErrorMessage>
                                </div>
                                <div className={"flex flex-col"}>
                                    <label htmlFor={"telefone"} className={labelStyles}>Telefono*</label>
                                    <Field type={"tel"} name={"telefone"} className={inputStyles} />
                                    <ErrorMessage name={"telefone"}>{message =>
                                        <p className={"text-red-500"}>{message}</p>
                                    }</ErrorMessage>
                                </div>
                                <div className={"flex flex-col"}>
                                    <label htmlFor={"email"} className={labelStyles}>Correo electrónico*</label>
                                    <Field type={"email"} name={"email"} className={inputStyles} />
                                    <ErrorMessage name={"email"}>{message =>
                                        <p className={"text-red-500"}>{message}</p>
                                    }</ErrorMessage>
                                </div>
                                <div className={"flex flex-col"}>
                                    <label htmlFor={"numberOfPeople"} className={labelStyles}>Número de personas*</label>
                                    <Field type={"number"} name={"numberOfPeople"} className={inputStyles} min={0}/>
                                    <ErrorMessage name={"numberOfPeople"}>{message =>
                                        <p className={"text-red-500"}>{message}</p>
                                    }</ErrorMessage>
                                </div>

                                <NormalTextareaComponent field={"allergens"} label={"Alergenos en las personas"} labelStyles={labelStyles} inputStyle={inputStyles} description={formikProps.initialValues.allergens} setDescription={setAllergens}/>

                                <div className={"flex flex-col"}>
                                    <label htmlFor={"isTermsAndPrivacyAccepted"} className={labelStyles}>
                                        <Field type={"checkbox"} name={"isTermsAndPrivacyAccepted"} className={inputStyles} />
                                        &nbsp;&nbsp;*Acepto los <span className={"text-primary cursor-pointer"} onClick={() => {
                                            setBodyModal({
                                                title: 'Terminos y Condiciones',
                                                message: others.termsAndConditions,
                                                buttonText: 'Cerrar'
                                            });
                                            setShowModal(true);
                                        }
                                        }>Terminos y Condiciones</span> y Acepto las <span className={"text-primary cursor-pointer"} onClick={() => {
                                        setBodyModal({
                                            title: 'Políticas de Privacidad',
                                            message: others.privacyAndPolicies,
                                            buttonText: 'Cerrar'
                                        });
                                        setShowModal(true);
                                    }
                                    }>Políticas de Privacidad</span>
                                        <ErrorMessage name={"isTermsAndPrivacyAccepted"}>{message =>
                                            <p className={"text-red-500 font-normal"}>{message}</p>
                                        }</ErrorMessage>
                                    </label>
                                </div>

                                <div className={"flex flex-col"}>
                                    <label htmlFor={"isTheyWantReceiveOffers"} className={labelStyles}>
                                        <Field type={"checkbox"} name={"isTheyWantReceiveOffers"} className={inputStyles} />
                                        &nbsp;&nbsp;Acepta recibir notificaciones de ofertas del restaurante
                                    </label>
                                </div>

                                <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                            </Form>
                        )}
                    </Formik>
                </div>
                <Transition
                    appear={true}
                    show={showModal}
                    enter={"transition-opacity duration-300"}
                    enterFrom={"opacity-0"}
                    enterTo={"opacity-100"}
                    leave={"transition-opacity duration-200"}
                    leaveFrom={"opacity-100"}
                    leaveTo={"opacity-0"}>
                    <InformationModalComponent title={bodyModal.title} message={bodyModal.message} buttonText={bodyModal.buttonText} onChange={setShowModal} />
                </Transition>
            </div>
        </>
    );
};

export default ReservationFormComponent;
