import axios, {AxiosResponse} from "axios";

axios.defaults.baseURL = 'https://aulaga-api.restaurantepedagogicoaulaga.es/api/';
// axios.defaults.baseURL = 'http://localhost:5000/api';

const responseBody = (response: AxiosResponse) => response.data;

const responseGetBody = (response: AxiosResponse) => {
    return {
        weekMenu: response.data,
        totalCountRecords: parseInt(response.headers['totalcountrecords'], 10)
    }
};

const request = {
    get: (url: string, token: string) => axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    listed: (url: string, token: string, page: number, recordsPerPage: number) => axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            page,
            recordsPerPage
        }
    }).then(responseGetBody),
    post: (url: string, formData: FormData, token: string) => axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    put: (url: string, formData: FormData, token: string) => axios.put(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    delete: (url: string, token: string) => axios.delete(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody)
};

const WeekMenus = {
    list: (token: string, page: number, recordPerPage: number) : Promise<{ weekMenu: any; totalCountRecords: number }> => request.listed('/weekmenu', token, page, recordPerPage),
    get: (id: number, token: string) => request.get(`/weekmenu/${id}`, token),
    create: (weekMenu: FormData, token: string, name: string) => request.post(`/weekmenu/${name}`, weekMenu, token),
    put: (id: number, weekMenu: FormData, token: string, name: string) => request.put(`/weekmenu/${id}/${name}`, weekMenu, token),
    delete: (id: number, token: string, name: string) => request.delete(`/weekmenu/${id}/${name}`, token)
};

export default WeekMenus;
