import React, {useContext} from "react";
import {NavLink, useHistory} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import packageJson from '../../../package.json';

interface INavbarComponent {
    showSidebar: boolean,
    setShowSidebar: any
}

const NavbarComponent = (props: INavbarComponent) => {
    const listStyles = "flex items-center text-white px-8 h-16 w-56";
    const versionStyles = "flex italic items-center text-white text-sm px-8 h-8 w-56";

    const { user, userDispatch } = useContext(AuthContext);
    const history = useHistory();

    const handleLogout = () => {
        userDispatch({
            user: null,
        });

        history.replace('/login')
    };

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    return (
      <div className={((props.showSidebar) ? "absolute w-screen h-screen bg-black-rgba inset-y-0 left-0 transform translate-x-0 transition duration-200 ease-in-out "
          : "absolute inset-y-0 left-0 transform -translate-x-full transition duration-200 ease-in-out ") + " lg:relative lg:translate-x-0 lg:w-auto z-40"}>
          <div className={"flex flex-col h-screen w-max bg-secondary justify-between"}>
              <div className={"flex flex-col overflow-y-auto"}>
                  <div className={"flex flex-row justify-end block lg:hidden p-4"}>
                      <button onClick={onChangeShow}>
                          <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 21 21">
                              <path id="Icon_material-close" data-name="Icon material-close" fill="#fff" d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
                          </svg>
                      </button>
                  </div>
                  <NavLink to={"/day"} className={listStyles} activeClassName={"bg-primary"} onClick={onChangeShow}>Administrar días</NavLink>
                  {user?.user?.userResponse?.role === "profesor" ?
                      <div>
                          <NavLink to={"/history-days"} className={listStyles} activeClassName={"bg-primary"} onClick={onChangeShow}>Historial de días</NavLink>
                          <NavLink to={"/week-menus"} className={listStyles} activeClassName={"bg-primary"} onClick={onChangeShow}>Administrar menú de la semana</NavLink>
                          <NavLink to={"/plates"} className={listStyles} activeClassName={"bg-primary"} onClick={onChangeShow}>Platos</NavLink>
                          <NavLink to={"/users"} className={listStyles} activeClassName={"bg-primary"} onClick={onChangeShow}>Gestión de usuarios</NavLink>
                          <NavLink to={"/team"} className={listStyles} activeClassName={"bg-primary"} onClick={onChangeShow}>Equipo</NavLink>
                          <NavLink to={"/other"} className={listStyles} activeClassName={"bg-primary"} onClick={onChangeShow}>Otros aspectos</NavLink>
                          <NavLink to={"/log"} className={listStyles} activeClassName={"bg-primary"} onClick={onChangeShow}>Logs</NavLink>
                      </div> : null}
              </div>
              <div className={"flex flex-col"}>
                  <p className={listStyles}>{user?.user?.userResponse?.name}</p>
                  <button className={listStyles} onClick={() => handleLogout()}>Cerrar sesión</button>
                  <p className={versionStyles}>{packageJson.version}v</p>
              </div>
          </div>
      </div>
    );
};

export default NavbarComponent;
