import React, {useContext, useEffect, useReducer, useState} from 'react';
import {IReservation} from "../../models/reservation";
import reservation from "../../api/reservation";
import LoadingComponent from "../ui/LoadingComponent";
import ModalComponent from "../ui/ModalComponent";
import {reservationReducer} from "../../reducers/reservationReducer";
import { useHistory } from 'react-router-dom';
import {AuthContext} from "../../context/AuthContext";

interface IReservationListComponent {
    dayId: any,
    maxPeople: any,
    showNotification?: any,
    messageNotification?: any
}

const ReservationListComponent = (props: IReservationListComponent) => {
    const initialState: IReservation[] = [];

    const history = useHistory();
    const { user, userDispatch } = useContext(AuthContext);

    let peopleCounter = 0;

    const [state, dispatch] = useReducer(reservationReducer, initialState);
    const [loading, setLoading] = useState(false);

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    const loadList = () => {
        setLoading(true);
        reservation.list(props.dayId, user?.user?.token as string)
            .then(response => {
                const action = {
                    type: 'charge',
                    payload: response
                };

                dispatch(action);
                setLoading(false);
            }).catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadList()
    }, []);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>

            <div className={ (loading) ? "hidden" : "block h-full w-full overflow-y-scroll px-2"}>
                <div className={"mt-4 flex flex-row w-full flex-wrap gap-1.5 lg:gap-2 items-center animate-fade-in"}>
                    {state.map((value: any) => (
                        value.map((trueValue: IReservation) => {
                            peopleCounter = peopleCounter + trueValue.numberOfPeople;

                            return (
                                <div key={trueValue.id} className={"flex flex-col p-4 w-full md:w-auto shadow-custom rounded-lg"}>
                                    <div className={"flex flex-row items-center"}>
                                        <div className={"h-16 w-16 p-4 mr-2 flex flex-row items-center"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.78 42.78">
                                                <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M21.39,24.064A12.032,12.032,0,1,0,9.358,12.032,12.035,12.035,0,0,0,21.39,24.064Zm10.7,2.674h-4.6a14.545,14.545,0,0,1-12.182,0H10.7A10.694,10.694,0,0,0,0,37.433V38.77A4.012,4.012,0,0,0,4.011,42.78H38.77A4.012,4.012,0,0,0,42.78,38.77V37.433A10.694,10.694,0,0,0,32.085,26.738Z" fill="#6B0DBC"/>
                                            </svg>
                                        </div>
                                        <div className={"flex flex-col"}>
                                            <p className={"font-bold text-base"}>{trueValue.name}</p>
                                            <p className={"font-bold text-base"}>{trueValue.surname}</p>
                                        </div>
                                    </div>

                                    <p><span className={"text-primary"}>Número de personas: </span>{trueValue.numberOfPeople}</p>
                                    <p className={(trueValue.isTheyWantReceiveOffers) ? "" : "hidden"}><span className={"text-primary"}>Email: </span>{trueValue.email}</p>
                                    <p className={(trueValue.isTheyWantReceiveOffers) ? "" : "hidden"}><span className={"text-primary"}>Teléfono: </span>{trueValue.telefone}</p>
                                    <p><span className={"text-primary"}>Políticas de Privacidad y Terminos: </span>{(trueValue.isTermsAndPrivacyAccepted) ? "Aceptó" : "No Aceptó"}</p>
                                    <p><span className={"text-primary"}>Quiere recibir ofertas: </span>{(trueValue.isTheyWantReceiveOffers) ? "Aceptó" : "No Aceptó"}</p>
                                </div>
                            )
                        })
                    ))}
                </div>
            </div>

        </>
    );
};

export default ReservationListComponent;
