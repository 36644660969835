import axios, {AxiosResponse} from "axios";
import {IReservation, IReservationForCreation} from "../models/reservation";

axios.defaults.baseURL = 'https://aulaga-api.restaurantepedagogicoaulaga.es/api/';
//axios.defaults.baseURL = 'http://localhost:5000/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string, token: string) => axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}, token: string) => axios.put(url, body, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
    patch: (url: string, body: {}, token: string) => axios.patch(url, body, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody)
};

const Reservations = {
    list: (dayId: number, token: string) : Promise<IReservation[]> => request.get(`/reservation/day/${dayId}`, token),
    get: (id: string, token: string) : Promise<IReservationForCreation> => request.get(`/reservation/${id}`, token),
    create: (reservation: IReservationForCreation) => request.post('/reservation', reservation),
    put: (id: string, dayId: number, reservation: IReservationForCreation, token: string, name: string) => request.put(`/reservation/${id}/${dayId}/${name}`, reservation, token),
    delete: (id: string, dayId: number) => request.delete(`/reservation/${id}/${dayId}`),
    confirm: (id: string, body: any, token: string, name: string) => request.patch(`/reservation/${id}/${name}`, body, token)
};

export default Reservations;
